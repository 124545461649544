@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

  .q-tree {
    margin-top: 1rem;
    &.app-tree-finished {
      animation: finish-animation 1s ease-in-out;
      @keyframes finish-animation {
        0% {
          /* Define the initial state */
          background: transparentize($positive, .75);
        }
        100% {
          /* Define the final state */
          background: unset;
        }
      }
    }
    .q-tree__arrow {
      margin: 0 2px 4px -4px
    }
    .q-tree__node {
      .app-recently-updated-node {
        .app-progress-badge {
          animation: pulse 2s infinite;
        }
      }
      .q-tree__node--parent, .q-tree__node--child {
        padding-top: .25rem;
        &:first-of-type {
          padding-top: .75rem;
        }
        .q-tree__node-header {
          margin: unset;
          padding: 0.25rem 0;
          &:hover {
            background-color: darken($background2, 10%);
          }
        }
      }
      .app-flowexecution-top-label-wrapper {
        max-width: 100%;
        white-space: nowrap;
      }
      .q-tree__node-header-content {
        .app-flowexecution-top-label-wrapper {
          position: absolute;
          top: -.75rem;
        }
        .app-flowececution-wrapper {
          background-color: darken($background2, 10%);
          background-repeat: no-repeat;
        }
      }
    }
  }
  .q-tree > .q-tree__node > .q-tree__node-header .app-flowexecution-top-label-wrapper {
    top: 0;
  }
  .q-tree .q-tree__node .q-tree__node-header-content {
    margin-top: .25rem;
  }
  .q-tree__node {
    max-width: 1080px;
  }
  .q-tree__node {
    .q-tree__arrow {
      margin: 0 0 6px 0;
    }
    &:last-child {
      .q-tree__node-header::before {
        bottom: .75rem;
        @media (min-width: $breakpoint-xs) {
          top: -1.5rem;
        }
      }
    }
  }
  .q-tree__children {
    width: 100%;
    padding-left: 8px;
    @media (min-width: $breakpoint-xs) {
      padding-left: 20px;
      padding-right: 20px;
      width: auto;
      min-width: 480px; /* Might require extra adjustment for deep levels */
    }
  }
  .q-tree .q-badge {
    margin: .25rem;
    padding: .25rem;
    max-height: 1rem;
    font-weight: 600;
    @media (min-width: $breakpoint-xs) {
      margin: unset;
    }
  }
  .app-flow-execution-refresh-btn {
    button:first-of-type {
      padding-right: .5rem;
    }
    .q-btn__content {
      font-weight: 600;
    }
    &.loading button:first-of-type i {
      animation: running-animation 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    }
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0px transparentize($primary, .2);
    }
    100% {
      box-shadow: 0 0 0 10px transparentize($primary, 1);
    }
  }
  @keyframes pulse-dark {
    0% {
      box-shadow: 0 0 0 0px transparentize($secondary, .2);
    }
    100% {
      box-shadow: 0 0 0 10px transparentize($secondary, 1);
    }
  }
  @keyframes running-animation {
    0% {
      transform: scale(.8) rotate(0deg);
    }
    25% {
      transform: scale(.8) rotate(90deg);
    }
    50% {
      transform: scale(.8) rotate(180deg);
    }
    75% {
      transform: scale(.8) rotate(270deg);
    }
    100% {
      transform: scale(.8) rotate(360deg);
    }
  }
  .app-flex-execution-detail-tabs .q-tab {
    max-width: 552px;
  }

  .app-all-filtered-info {
    color: $gray;
  }

  .q-page-container .q-page .q-tab-panels.app-flow-execution-detail-panel {
    background-color: $background2;
    border-radius: 4px;
  }

  .app-tree-action-bar .q-icon {
    display: inline-block;
  }

  .app-flowexecution-wrapper {
    background-color: darken($background2, 10%);
  }

  .fixed-tooltip {
    // Overwrite quasar default behaviour of tooltips
    position: fixed;
    top: unset !important;
    bottom: 0;
    left: 0 !important;
    width: 100%;
  }

  body.body--dark {
    .q-tree {
      .q-tree__node {
        .app-recently-updated-node {
          .app-progress-badge {
            animation: pulse-dark 2s infinite;
          }
        }
        .q-tree__node--parent, .q-tree__node--child {
          .q-tree__node-header {
            &:hover {
              background-color: $dark-page;
            }
          }
        }
      }
    }
    .q-page-container .q-page .q-tab-panels.app-flow-execution-detail-panel {
      background-color: $dark;
    }
    .app-flowexecution-wrapper {
      background-color: lighten($dark, 10%);
    }
  }
