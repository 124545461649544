@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.app-browser-filesystem-item-wrapper {
  cursor: pointer;
  transition: .33s;
  .app-browser-filesystem-item {
    padding: .25rem .5rem;
  }
}
.q-tree.app-browser-filetree > .q-tree__node--child {
  > .q-tree__node-header {
    padding-left: unset;
  }
}

.app-filemanager .q-item {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  &.active {
    i.q-icon {
      color: $primary;
    }
  }
  &.renaming {
    border: 2px dashed black;
  }
}

.app-filemanager {
  .q-focusable:focus > .q-focus-helper, body.desktop .q-manual-focusable--focused > .q-focus-helper, body.desktop .q-hoverable:hover > .q-focus-helper,
  .q-focus-helper:before {
    background: unset !important;
  }
}

body.body--light {
  .app-browser-filesystem-item-wrapper,
  .app-filemanager .q-item {
    &.active {
      background: mix($dark, $background, 10%);
    }
    &:hover {
      background: mix($dark, $background, 15%);
    }
  }
}
body.body--dark {
  .app-browser-filesystem-item-wrapper,
  .app-filemanager .q-item {
    &.active {
      background: mix($light, $dark-page, 10%);
    }
    &:hover {
      background: mix($light, $dark-page, 15%);
    }
  }
}
