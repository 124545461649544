
  .app-page-password-reset {
    &.disabled {opacity: .3}
    .lds-ring {
      transform: translateX(-50%);
      top: 10rem;
    }
  }
  .app-login-input-wrapper {
    margin: -1.25rem;
  }
  .app-login-input-name, .app-login-input-password, .app-login-input-password-confirm {
    max-width: 400px;
  }
  .psw-res {
    .q-field__control:before,
    .q-field__control:after {
      left: 1rem;
    }
  }
