@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.app-items-per-page-select {
  background-color: $background2;
  width: 10rem;
}

body.body--dark {
  .app-items-per-page-select {
    background-color: $dark;
  }
}
