.app-flow-status-badge {
  /*&.running {
    animation: running-animation 3s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }
  @keyframes running-animation {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(270deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }*/
}

.q-badge {
  &.bg-positive {
    background-color: $positive-bg !important;
    color: $positive;
  }
  &.bg-warning {
    background-color: $warning-bg !important;
    color: $warning;
  }
  &.bg-negative {
    background-color: $negative-bg !important;
    color: $negative;
  }
  &.bg-gray {
    background-color: lighten($gray, 20%) !important;
    color: $dark;
  }
}

body.body--dark {
  .q-badge {
    background-color: $primary-bg-dark !important;
    &.bg-null {
      color: $primary-dark-page-font;
    }
    &.bg-positive {
      background-color: $positive-bg-dark !important;
    }
    &.bg-warning {
      background-color: $warning-bg-dark !important;
    }
    &.bg-negative {
      background-color: $negative-bg-dark !important;
    }
    &.bg-gray {
      background-color: darken($gray, 20%) !important;
      color: lighten($gray, 10%);
    }
  }
}

/*.app-flow-item .app-flow-status-badge {
  width: 1rem;
  height: 1rem;
  .q-icon {
    transform: scale(.75);
  }
}

.app-flow-detail .app-flow-status-badge {
  width: 1.25rem;
  height: 1.25rem;
}*/

.q-badge.app-badge-round {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 12px;
}

/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse {
  position: relative;
  left: -9970px;
  width: 2px;
  height: 2px;
  border-radius: 5px;
  background-color: $primary;
  color: $primary;
  box-shadow: 9999px 0 0 -5px $primary;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: .25s;
}

.dot-pulse::before, .dot-pulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 2px;
  height: 2px;
  border-radius: 5px;
  background-color: $primary;
  color: $primary;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px $primary;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px $primary;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: .5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px $primary;
  }
  30% {
    box-shadow: 9984px 0 0 2px $primary;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px $primary;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px $primary;
  }
  30% {
    box-shadow: 9999px 0 0 2px $primary;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px $primary;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px $primary;
  }
  30% {
    box-shadow: 10014px 0 0 2px $primary;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px $primary;
  }
}
