@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.app-flow-execution-detail-panel, .app-flow-execution-detail-panel .q-panel.scroll {
  overflow: unset; // required for sticky action bar to work
}
.app-flow-execution-detail-panel.app-tab-transitioning.q-panel-parent {
  overflow: hidden; // to prevent elements flying visually out of the box
}
.app-flow-execution-detail-page .app-jump-to-execution {
  font-weight: 600;
  &:not(:hover) {
    img.q-icon {
      filter: $secondarize;
    }
  }
}

body.body--dark {
  .app-flex-execution-detail-tabs {
    .q-tab:not(.q-tab--active) {
    .q-icon {
      filter: $darkmodize;
    }
  }
  }
}

.app-flex-execution-detail-tabs {
  .q-tab--active {
    .q-icon {
      filter: $primarize;
    }
  }
  .q-icon {
    width: 1rem;
    height: 1rem;
  }
}
