@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

  .app-select-fqcn-input {
    border: unset;
    border-bottom: $layout-border;
    background-color: $background2;
    &:focus-visible {
      outline: unset;
    }
  }
  .app-select-fqcn-input, .app-select-process-step-input {
    .q-field__inner .q-field__control {
      padding: 0 .5rem;
    }
  }
  body.body--dark {
    .app-select-fqcn-input {
      background-color: $dark-page;
    }
  }
