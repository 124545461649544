@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

  .app-heartbeat-headline-container {
    .app-heartbeat-headline {
      line-height: 1;
      font-size: 1rem;
    }
  }

  @mixin heartbeat-color($color) {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="' + $color + '" width="76.044" height="43.415" id="Schnittmenge_4" data-name="Schnittmenge 4" viewBox="0 0 76.044 43.415"%3E%3Cpath d="M10.179,43.407a1.781,1.781,0,0,1-1.687-1.267L2.757,20.931,1.1,25.522A1.8,1.8,0,0,1-.615,26.667H-11.479a1.747,1.747,0,0,1-1.811-1.674,1.747,1.747,0,0,1,1.811-1.674H-1.92l3.209-8.9a1.783,1.783,0,0,1,1.786-1.143,1.781,1.781,0,0,1,1.686,1.267L10.5,35.751,23.016,1.024a1.95,1.95,0,0,1,3.434,0L38.968,35.751,44.7,14.542a1.782,1.782,0,0,1,1.687-1.267,1.741,1.741,0,0,1,1.786,1.143l3.209,8.9h9.559a1.884,1.884,0,0,1,1.28.49,1.587,1.587,0,0,1,0,2.368,1.887,1.887,0,0,1-1.28.49H50.081a1.8,1.8,0,0,1-1.717-1.145l-1.655-4.59L40.973,42.14a1.781,1.781,0,0,1-1.687,1.267l-.069,0A1.8,1.8,0,0,1,37.5,42.264L24.733,6.848,11.965,42.264a1.667,1.667,0,0,1-1.623,1.15Q10.261,43.414,10.179,43.407Z" transform="translate(13.289 0.001)" opacity=".10" /%3E%3C/svg%3E');
  }

  @function encode-color($string) {
    @if type-of($string) == 'color' {
      $hex: str-slice(ie-hex-str($string), 4);
      $string:unquote("#{$hex}");
    }
    $string: '%23' + $string;
    @return $string;
  }

  .app-heartbeat {
    &.app-heartbeat-positive {
      background-color: $positive-bg;
      @include heartbeat-color(encode-color($positive));
    }
    &.app-heartbeat-warning {
      background-color: $warning-bg;
      @include heartbeat-color(encode-color($warning));
    }
    &.app-heartbeat-negative {
      background-color: $negative-bg;
      @include heartbeat-color(encode-color($negative));
    }
    &.app-heartbeat-none {
      .app-heartbeat-delay-info {
        color: $dark;
      }
    }
    background-repeat: no-repeat no-repeat;
    background-position: left top;
    background-size: contain;
    .app-heartbeat-row-top {
      padding-bottom: 0;
    }
    .app-heartbeat-row-bottom {
      padding-top: 0;
    }
  }

  body.body--dark {
    .app-heartbeat {
      &.app-heartbeat-positive {
        background-color: $positive-bg-dark;
      }
      &.app-heartbeat-warning {
        background-color: $warning-bg-dark;
      }
      &.app-heartbeat-negative {
        background-color: $negative-bg-dark;
      }
      &.app-heartbeat-none {
        .app-heartbeat-delay-info {
          color: $light;
          .q-icon:first-child {
            filter: invert(1);
          }
        }
      }
      .app-heartbeat-delay-info {
        .q-icon:first-child {
          filter: invert(1);
        }
      }
    }
  }
