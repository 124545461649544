.error-modal {
  font-size: 1rem;
  
  &-trace {
    &-item { 
      pre {
        white-space: revert;
      }
      .q-expansion-item .q-item {
        padding: 0;

        .q-item__label {
          font-weight: bold;
        }
      }
    }
  }
}