@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

  .entity-list-container {
    min-height: 100px;
  }

  .q-card__section {
    padding: .25rem;
  }

  .app-entity-link {
    cursor: pointer;
    &.active {
      color: $primary;
    }
    &:hover {
      color: $secondary;
    }
  }

  .app-entity-badge {
    padding: 0 .25rem;
    background-color: $light;
    color: $dark;
  }

  body.body--dark {
    .app-entity-link:hover {
      color: $dark;
      background-color: $secondary;
    }
    .app-entity-badge {
      background-color: $dark;
      color: $light;
    }
  }

  .q-item__label {
    align-items: center;
    display: flex;
  }
  .app-entity-list-reload {
    cursor: pointer;
    &:hover {
      color: $secondary;
    }
  }
