@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

  .app-build {
    color: $subtext;
  }
  .app-toolbar {
    background-color: $background;
  }
  body.body--dark {
    .app-toolbar {
      background-color: $dark-page;
    }
  }
