
  .failed-messages-modal {
    .failed-messages-modal-content {
      margin: 24px 24px;
      body.screen--xs & {
        margin: 24px 0;
      }
    }
    .q-chip {
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.375rem;
      letter-spacing: 0.00714em;
    }
    .q-item {
      padding-left: 24px;
      padding-bottom: 24px;
      body.screen--xs & {
        padding-left: 0;
        padding-bottom: 24px;
      }
    }
  }
