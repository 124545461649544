@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.app-dashcard-container {
  margin-right: -1rem;
}
.app-time-series-visualization {
  .q-card {
    cursor: pointer;
  }
}

.app-heartbeat-card {
  @media (min-width: $breakpoint-sm) {
    margin-left: .5rem;
  }
}

@media (max-width: $breakpoint-xs) {
  .app-heartbeat-container.app-supervisor-container.app-message-queue-container.app-disk-space-container.app-redis-memory-usage-container {
    margin-right: 1rem;
  }
  .app-message-queue-card {
    padding-top: 0 !important;
  }
}
.app-supervisor {
  margin-right: 1rem !important;
}
@media (min-width: $breakpoint-sm) {
  .app-disk-space {
    margin-right: 1rem !important;
  }
}
.app-message-queue-card, .app-supervisor-card, .app-disk-space-card, .app-redis-memory-usage-card, .app-redis-memory-usage-card {
  min-height: 178.58px;
}
