@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

  .app-user div {
    line-height: 1.25;
  }
  .app-name-accent1 {
    font-weight: bold;
    color: $dark;
  }
  .app-name-accent2 {
    font-weight: bold;
    color: $primary;
  }
  .app-avatar {
    background: $primary;
    color: $light;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .app-user {
    .app-user-real-name {
      font-weight: bold;
    }
    .app-user-technical-name {
      font-weight: normal;
      font-size: .8rem;
    }
  }
  .app-topbar-btn {
    color: $dark;
  }
  .app-user-viewport-xs {
    .app-avatar {
      display: none;
    }
    .app-user {
      margin-left: unset;
      .app-user-real-name, .app-user-technical-name {
        margin-left: unset;
        max-width: 35vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  body.body--light {
    .app-user-real-name {
      color: $dark;
    }
    .app-user-technical-name {
      color: $subtext;
    }
  }

  body.body--dark {
    .q-drawer {
      .app-name-accent1 {
        color: white;
      }
    }
    .app-avatar {
      background: $primary-dark-page;
    }
    .app-user-real-name, .app-topbar-btn {
      color: $light;
    }
    .app-user-technical-name {
      color: $grey;
    }
  }

