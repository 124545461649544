
  .app-login-form {
    position: relative;
    z-index: 1;
  }
  .app-login-input-wrapper {
    margin: -1.25rem;
    .q-field__control:before, .q-field__control:after {
      left: 1rem;
    }
  }
  .app-password-recover {
    text-decoration: underline;
  }
  .app-login-input-password, .app-login-input-name, .app-password-recover {
    max-width: 400px;
  }
