
  .jdm-config-builder {
    min-height: calc(100vh - 140px);
  }

  .preference-popup {
    min-width: 250px;
  }

  .flow-name {
    line-height: 35px;
  }
