
  .app-menu a:hover {
    text-decoration: none;
  }
  .q-item__section--avatar {
    padding-right: 0;
    min-width: 2.5rem;
  }
  .q-item:not(.q-router-link--active) .q-item__section--side > .q-icon {
    filter: grayscale(1);
  }
