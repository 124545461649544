@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

  .app-page-password-recover {
    &.disabled {opacity: .3}
    .lds-ring {
      transform: translateX(-50%);
      top: 10rem;
    }
    .q-field__control:before, .q-field__control:after {
      left: 0;
    }
  }
  .app-submit-send-info,
  .app-submit-sent {
    opacity: 0;
    transition: .5s;
    transform: translateY(-10px);

    &.show {
      opacity: 1;
      transform: unset;
    }
  }
  .app-submit-sent {
    color: $positive;
    &.app-fail {
      color: $negative;
    }
  }
  .psw-req {
    .q-field__control:before,
    .q-field__control:after {
      left: 0;
    }
    .app-submit-send-info {
      display: inline-block;
      opacity: 0;
      transition: .5s;
      transform: translateY(-10px);
      &.show {
        opacity: 1;
        transform: unset;
      }
    }
  }
