@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

  @media (min-width: $breakpoint-xs) {
    .app-create-filesystem-name-input {
      width: 50%;
    }
  }

  .dialog-title {
    line-height: 1rem;
  }
