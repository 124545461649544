@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

  .app-browser-filesystem-item-wrapper {
    cursor: pointer;
    transition: .33s;
    .app-browser-filesystem-item {
      padding: .25rem .5rem;
    }
  }
  .q-tree.app-browser-filetree > .q-tree__node--child {
    > .q-tree__node-header {
      padding-left: unset;
    }
  }

  body.body--light {
    .app-browser-filesystem-item-wrapper {
      &.active {
        background: mix($dark, $background, 10%);
      }
      &:hover {
        background: mix($dark, $background, 15%);
      }
    }
  }
  body.body--dark {
    .app-browser-filesystem-item-wrapper {
      &.active {
        background: mix($light, $dark-page, 10%);
      }
      &:hover {
        background: mix($light, $dark-page, 15%);
      }
    }
  }
