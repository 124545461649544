.q-field__control-container {
  .q-field__label {
    padding-bottom: 1rem;
  }
  input, textarea, select {
    border: 1px solid transparent;
    &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus {
      border: 1px solid $secondary;
      -webkit-text-fill-color: $secondary;
      -webkit-box-shadow: 0 0 0 1000px $background inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }
}
.app-items-per-page-select {
  padding: 0.25rem 0.5rem;
}
.q-field--dense .q-field__label {
  padding-top: 0.25rem;
}

pre {
  white-space: pre-line;
}
body.body--dark {
  .q-dialog .q-field--dark .q-field__control:before,
  .q-field__bottom .q-field--dark .q-field__control:before {
    border-color: rgba(255, 255, 255, 0.6)
  }
  input, textarea, select {
    &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus {
      border: 1px solid $secondary;
      -webkit-text-fill-color: $secondary;
      -webkit-box-shadow: 0 0 0 1000px $dark-page inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }
}
