@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.app-cache-usage-table {
  td:first-child {
    max-width: 1rem;
    background-color: $background2;
  }

  th:first-child,
  td:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
  }

  @media (max-width: $breakpoint-sm) {

    // Attention: max-width, since definition only required for small screen. Default on desktop is good.
    th,
    td {
      padding: 0 .3rem;
    }
  }
}

body.body--dark {
  .app-cache-usage-table {
    td:first-child {
      max-width: 1rem;
      background-color: $dark-page;
    }

    th:first-child,
    td:first-child {
      position: sticky;
      left: 0;
      z-index: 1;
    }
  }
}

.app-cache-list-reload {
    cursor: pointer;
    &:hover {
      color: $secondary;
    }
  }
