.q-avatar__content {
  height: 100%;
}
.app-icon-flow-start, .app-icon-flow-stop {
  &.q-icon {
    width: 1rem;
    height: 1rem;
    margin-right: .4rem;
  }
}
.app-icon-flow-stop {
  color: $icon-color;
}
body.body--dark {
  .app-topbar-btn, .app-icon-flow-start, .app-icon-flow-stop {
    filter: invert(1);
  }
}
// negative-hover class in buttons
.q-btn.negative-hover {
  &:hover, &:active, &:focus {
    .q-icon {
        color: $negative;
    }
  }
}

.q-layout > div .q-icon img { // Overwrite quasar icons, which uses 1em, in body (but not in header)
  width: 1rem;
  height: 1rem;
}
