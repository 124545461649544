// code highlighting light mode
.ace-iplastic {
  .ace_entity.ace_name.ace_function, 
  .ace_entity.ace_other, 
  .ace_entity.ace_other.ace_attribute-name, 
  .ace_variable {
    color: var(--q-primary);
  }
  .ace_constant.ace_character, 
  .ace_constant.ace_language, 
  .ace_constant.ace_numeric, 
  .ace_constant.ace_other {
    color: var(--q-primary);
  }
  .ace_string {
    color: var(--q-accent);
  }
}

// code highlighting dark mode
.ace-pastel-on-dark {
  .ace_variable {
    color: color-mix(in srgb,var(--q-primary),#fff 60%);
  }
  .ace_string {
    color: color-mix(in srgb,var(--q-accent),#fff 60%);
  }
  .ace_constant.ace_language {
    color: color-mix(in srgb,var(--q-primary),#fff 30%);
  }
}

// misc
.ace-pastel-on-dark,
.ace-iplastic {
  // remove vertical line (print-margin)
 .ace_print-margin { display: none }
 // use secondary color for expand cta
 .ace_fold {
    transition: background-color 0.2s ease-out;
    background-color: color-mix(in srgb,var(--q-secondary),#000 10%);

    &:hover, &:focus, &:active {
      background-color: var(--q-secondary);
    }
 }
}
// active outline on editor focus
.ace_editor,
.json-editor {
  transition: border-color 0.2s ease-out, box-shadow 0.2s ease-out;
  border: 1px solid $editor-border-color;
  &.ace_focus {
    box-shadow: 0 0 3px var(--q-secondary);
  }
  &:hover {
    border-color: $secondary;
  }
}