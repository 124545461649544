
.q-drawer {
  .app-logo-wrapper {
    .app-logo {
      height: 3rem;
    }
  }
  .app-help {
    text-decoration: none;
    &:hover div {
      text-decoration: underline;
    }
  }
  .app-menu h2 {
    font-size: 1rem;
  }
  .q-item .q-item__section--side > .q-icon {
    font-size: 1.25rem;
    top: -1px;
  }
}
