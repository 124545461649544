
  .date-picker {
    :deep(.q-field__after) {
      width: 60%;
    }

    :deep(.q-field__bottom) {
      position: absolute;
    }

    .date-picker-input {
      :deep(.q-field__control-container) {
        padding-top: 0 !important;
      }
    }
  }
