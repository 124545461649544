.q-dialog {
  z-index: 9000;
}
.q-menu, .q-dialog .app-tooltip-mobile {
  z-index: 9001;
}

.error-modal-trace-item {
  pre {
    background-color: $light;
  }
}

.q-dialog__inner--minimized > div {
  background-color: $light;
}

body.body--dark .q-dialog__inner--minimized > div {
  background-color: $dark-page;
}
