@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

  .app-entity-query-editor-wrapper {
    background-color: $background2;
    .app-entity-query-editor {
      margin: 0;
      overflow: visible;
    }
  }

  .app-entity-page-container {
    order: 2;
    @media (min-width: $breakpoint-xs) {
      order: unset;
    }
    padding: 1rem .25rem;
    @media (min-width: $breakpoint-xs) {
      padding: 1rem;
    }
  }
  .app-entity-list-container-wrapper {
    order: 1;
    @media (min-width: $breakpoint-xs) {
      order: unset;
    }
    .app-entity-list-container {
      margin: 1.5rem;
      @media (min-width: $breakpoint-xs) {
        position: sticky;
        top: 4rem;
      }
      .app-entity-list-container-inner {
        padding-left: 1rem;
        border-left: $layout-border;
        h2 {
          font-size: .8rem;
          font-weight: 600;
        }
      }
    }
  }
