
  .app-disk-space-card {
    .custom-chart-legend {
      background: none !important;
    }
  }

  .app-disk-space {
    min-height: 465px;
  }

  .apex-chart-container {
    min-height: 365px;
  }
