$footer-height: 58px; // When changing this value, always search for it in the whole app first, because there can also be hardcoded calc offsets, such as in the LogList.vue

.app-footer {
  background: $background;
  margin-top: -$footer-height; // FIX vertical scrollbar always being there: See UI-387
}
.q-page > div {
  margin-bottom: $footer-height;
}

body.body--dark {
    .app-footer {
        background-color: $dark-page;
    }
}
