@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

  .app-jdm-item {
    background: $background2;
    border: $layout-border;
    border-radius: $generic-border-radius;
    &:hover {
      border: 1px solid $secondary;
      cursor: pointer;
      z-index: 1;
    }
  }
  body.body--dark {
    .app-jdm-item {
      background: $dark;
      border: 1px solid $separator-dark-color
    }
  }
