.app-card-bg-icon {
  color: $gray;
  opacity: 10%;
  position: absolute;
  top: .5rem;
  left: .25rem;
}

.app-card-container {
  .app-card {
    @include user-select(none);
    .app-dashcard-label {
      font-size: 1rem;
    }
    &:hover {
      background-color: mix($background, $secondary, $mixVal);
      z-index: 1;
    }
  }
  .q-field__control {
    background-color: $background2;
  }
}

.app-dashcard, .app-heartbeat, .app-message-queue, .app-supervisor, .app-card {
  transition: .3s;
}

body.body--dark {
  .app-card-container {
    .app-card {
      &:hover {
        background-color: mix($dark-page, $secondary, $mixVal);
        z-index: 1;
      }
      .q-field__control {
        background-color: $dark-page;
      }
    }
  }
}
