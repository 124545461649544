@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

  .app-tree-action-bar {
    @media (min-width: $breakpoint-xs) {
      position: sticky;
      top: 3.5rem;
    }
    background-color: $background2;
    z-index: 1;
    padding-bottom: .4rem;
    padding-top: .5rem;
  }
  .app-flow-execution-refresh-btn {
    button:first-of-type {
      padding-right: .5rem;
    }
    .q-btn__content {
      font-weight: 600;
    }
    &.loading button:first-of-type i {
      animation: running-animation 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      @keyframes running-animation {
        0% {
          transform: rotate(0deg);
        }
        25% {
          transform: rotate(90deg);
        }
        50% {
          transform: rotate(180deg);
        }
        75% {
          transform: rotate(270deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  .app-all-filtered-info {
    color: $gray;
  }

  .app-tree-action-bar {
    .q-icon {
      display: inline-block;
    }
    .q-btn:not(.q-btn-dropdown__arrow-container) {
      padding: 0 .5rem;
    }
    .q-btn, .app-flow-execution-refresh-btn .q-btn__content {
      font-weight: 600;
      .q-icon:not(.q-btn-dropdown__arrow) {
        font-size: 1rem;
      }
    }
    .flex  {
      > *, > * .q-field__control {
        height: 2rem;
        .q-field__label {
          padding-top: unset;
        }
      }
    }
    .q-field:not(.q-field--focused.q-field--highlighted) {
      &.q-field--dense .q-field__label {
        transform: translateY(-3px);
      }
    }
  }

  body.body--dark {
    .app-tree-action-bar {
      background-color: $dark;
    }
  }
