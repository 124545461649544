body.body--dark {
  .q-item.q-router-link--active, .q-item--active {
    color: $primary-dark-page-font;
  }
  .q-item--dark {
    color: $light;
  }
  // helper class to invert icons at darkmode
  .invert-icon-dark {
    -webkit-filter: invert(1);
            filter: invert(1);
}

}
