
.validation-table {
  .severity-filter {
    height: auto;
    .q-toggle {
      min-width: 7rem;
    }
    body.screen--xs & {
      display: flex !important;
      justify-content: space-between !important;
      flex-wrap: wrap;
      width: auto;
    }
    .entity-filter {
      height: auto;
      body.screen--xs & {
        width: 100%;
        margin-left: 0;
      }
    }
    .search-filter {
      height: auto;
      body.screen--xs & {
        width: 100%;
        margin-left: 0;
      }
    }
    .validation-table-filter {
      background: none !important;
    }
  }
}
