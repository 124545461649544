@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

  .json-editor, .app-editor {
    z-index: 2998;
  }
  .json-editor {
    background: $background;
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
    min-width: 100%;
    resize: both; // If event handling is needed, add Quasar's resize observer
    &:not(.custom-min-height) {
      min-height: 12rem;
      .json-editor-wrapper {
        .json-editor__container {
          min-height: 80px;
        }
      }
    }
    &.contrast {
      background: $background2;
      color: $dark;
    }
    &:hover {
      border: 1px solid $secondary;
    }
    &:focus-within {
      box-shadow: 0 0 3px $secondary;
    }
  }
  .json-editor__textarea:focus {
    outline: none;
  }

  .json-editor-wrapper {
    .json-editor__container {
      min-height: 80px;
      height: 100%;
    }
  }

  body.body--light {
    .json-editor {
      background: $background;
      color: $dark;
      &.contrast, .contrast {
        background: $background2;
      }
    }
    textarea.json-editor__textarea::selection {
      background: #888;
    }
  }
  .q-editor-toolbar .q-btn.disabled {
    opacity: .3 !important;
  }

  .app-editor {
    &:not(.custom-max-height) {
      max-height: 50vh;
    }
  }

  .app-editor.invalid-code .json-editor {
    border: 1px solid $negative;
    &:focus-within {
      box-shadow: 0 0 3px $negative;
    }
  }


  .sq-editor-offset {
    margin-bottom: 2rem;
  }
  .sq-invalid-json-notif {
    position: sticky;
    display: flex;
    justify-content: flex-end;
  }

  .ace_editor {
    font-size: .75rem;
    .ace_variable {
      font-style: normal;
    }
    .ace_fold-widget {
      transform: scale(1.2);
    }
    .ace_marker-layer .ace_bracket {
      padding: 0.2rem;
      transform: translateX(1px);
      background-color: transparentize($secondary, .8);
    }
    .ace_print-margin {
      opacity: .2;
    }
  }
  .ace_editor.app-editor-light, .ace_marker-layer .ace_bracket {
    background-color: $background2;
    .ace_gutter, .ace_marker-layer .ace_active-line {
      background-color: $background;
    }
  }
  .ace_editor.app-editor-dark, .ace_gutter {
    background-color: $dark-page;
    .ace_marker-layer .ace_active-line, .ace_marker-layer .ace_bracket {
      background-color: $dark;
    }
  }

  body.body--dark {
    .json-editor {
      color: $light;
      caret-color: white;
      background: $dark-page;
      &.contrast, .contrast {
        background: #000;
      }
    }
    textarea.json-editor__textarea::selection {
      background: #444;
    }
  }
