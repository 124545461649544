@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.app-flow-item-action-menu {
  .q-btn .q-icon {
    font-size: 1rem;
  }
}
.app-flow-detail {
  .app-jump-to-execution {
    img.q-icon {
      filter: $secondarize;
    }
  }
}

.app-flow-item {
  .app-jump-to-execution {
    color: $dark;
  }
}
body.body--dark {
  .app-jump-to-execution {
    color: $secondary;
    background-color: mix($dark,$secondary,95%);
  }
  .app-flow-menu-dropdown .q-icon {
    filter: $darkmodize;
  }
  .app-flow-execution-detail-page {
    .app-jump-to-execution .q-icon {
      filter: $darkmodize;
    }
  }
}
