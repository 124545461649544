.app-linkable-graph .apexcharts-series {
  cursor: pointer;
}

.chart-filter {
  z-index: 1;
  padding: .5rem;
}

body.screen--md, body.screen--lg, body.screen--xl {
  .chart-filter {
    position: absolute;
    top: 0;
    right: 0;
    background: rgba($color: $background2, $alpha: .8);
  }
  &.body--dark .chart-filter {
    background: rgba($color: $dark, $alpha: .8);
  }
}
