@import url('https://fonts.googleapis.com/css2?family=Palanquin:wght@400;600;700&display=swap');

/*@font-face {
  font-family: 'Palanquin';
  src: url('https://fonts.googleapis.com/css2?family=Palanquin&display=swap');
}

@font-face {
  font-family: 'PalanquinBold';
  src: url('https://fonts.googleapis.com/css2?family=Palanquin:wght@700&display=swap');
  font-weight: bold;
}*/

html, body {
  font-family: $font;
}

h1 {
  margin-top: 2.5rem;
  font-size: 2rem;
  line-height: 1;
  margin-bottom: 1rem;
  font-weight: 400;
  @media only screen and (min-width: 1024px) { /* TODO cs: Opt: get quasar breakpoint variables to work here */
    margin-top: 0;
  }
}

.app-logged-in h1 {
  margin-top: 1rem;
  @media only screen and (min-width: 1024px) {
    margin-top: 0;
  }
}

h2 {
  font-size: 20px;
  line-height: 2;
  margin: unset;
}

a {
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
}

.app-text-weight-semibold {
  font-weight: 600;
}

* {
  word-break: break-word;
}

.app-text-break-all {
  word-break: break-all;
}

.text-gray, .text-light {
  color: $subtext
}

.line-height-1 {
  line-height: 1 
}

body.body--dark {
  .text-primary {
    color: $primary-dark-page-font !important;
  }
}
