
  .alert-entities-chart {
    min-height: 450px;

    .validation-graph {
      justify-content: right !important;
      align-items: self-end !important;
    }

    .apexcharts-canvas {
      margin: 0 0 0 auto;
    }

  }
