
  .app-dashcard {
    min-height: 80px;
    &.clickable {
      cursor: pointer;
    }
  }
  .app-dashcard-value {
    font-size: 3rem;
    line-height: .5;
  }
