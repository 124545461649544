@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.q-page-container .q-page .app-dashcard-container .app-dashcard.app-flow-execution-card {
  margin-left: unset;
}
.app-expansion-section .one-liner {
  max-width: 100%;
  &-child {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 600;
  }
}

.app-flow-detail {
  .app-jdm-item + .app-jdm-item {
    margin-top: 1rem;
  }
}

.app-flow-execution-card {
  padding: unset;
}

.app-flow-detail .controls .app-action-btn {
  margin-top: 1rem;
  @media (min-width: $breakpoint-sm) {
    margin-top: unset;
  }
}

.app-jdm-section {
  padding: 0 .8rem;
  .app-jdm-empty {
    padding: 0 .5rem;
  }
}

body.body--dark {
  .app-jump-to-execution {
    color: $light;
    .q-icon {
      filter: invert(1);
    }
  }
}
@media (max-width: $breakpoint-xs) {
  .last-execution-info {
    justify-content: flex-start;

    &>div { margin-right: 1rem; }
  }
}

.app-flow-detail .app-headline-container .app-edit-flowname {
  img {
    width: 1rem;
    height: 1rem;
    filter: $secondarize;
  }

  &:hover img {
    filter: invert(1);
  }
}
