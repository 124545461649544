
  .app-validation-table-filter-input {
    min-width: 150px;
    width: fit-content;
  }
  body.body--dark .q-table__container .app-validation-table-filter-input {
    &.q-field.q-select--multiple {
      background: unset;
    }
  }
