
  #particles-js {
    position: absolute;
    bottom: 5vh;
    right: 0;
    left: 0;
    width: 100%;
    height: 30vh;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  /* ---- reset ---- */
  canvas {
    display: block;
    vertical-align: bottom;
  }
