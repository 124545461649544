@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.app-entity-listing-row-container {
  background-color: $background2;
  max-width: 100vw;
  word-break: break-word;
  &.blocked {
    cursor: not-allowed;
  }
  &:hover {
    background-color: mix($background2,$secondary,95%);
  }
  &>div:not(.app-entity-listing-row-actions) {
    display: inline-block;
    word-break: break-all;
    line-height: 1;
  }
  &>.app-entity-listing-row-actions {
    max-height: 4rem;
    flex-wrap: nowrap;
    position: sticky;
    top: 4rem;
    left: 3rem;
    z-index: 1;
    .app-action-btn {
      padding: 1rem;
    }
    @media (max-width: $breakpoint-sm) {
    .app-action-btn {
      flex: 1 1 0;
      padding: .5rem
    }
    &>div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      }
    }
  }
  hr {
    border-top: $background2;
    border-bottom: unset;
  }
  .app-entity-listing-editor-wrapper {
    max-height: 5rem;
    &.expanded {
      max-height: unset;
    }
  }
  @media (max-width: $breakpoint-sm) {
  &>div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    }
  }
}

.app-placeholder-container {
  height: 100px;
  background-color: $background;
}

.app-entity-listing-row-actions {
  display: flex;
  flex-wrap: wrap;
}

body.body--dark {

  .app-entity-listing-row-container {
    background-color: $dark;
  }

  .app-placeholder-container {
    background-color: $dark-page;
  }
}
