@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
// app global css in SCSS form
/* ------------------------------------------------------------------------------------------------
  import variables
--------------------------------------------------------------------------------------------------- */
@import 'quasar.variables';
/* ------------------------------------------------------------------------------------------------
  import mixin files
--------------------------------------------------------------------------------------------------- */
@import 'mixin/interactions';
@import 'mixin/borders';
/* ------------------------------------------------------------------------------------------------
  import component files
--------------------------------------------------------------------------------------------------- */
@import 'component/alert';
@import 'component/badge';
@import 'component/button';
@import 'component/card';
@import 'component/chart';
@import 'component/decoration';
@import 'component/dialog';
@import 'component/error-modal';
@import 'component/editor';
@import 'component/form';
@import 'component/icon';
@import 'component/helper';
@import 'component/pagination';
@import 'component/spinner';
@import 'component/tab';
@import 'component/table';
@import 'component/tooltip';
/* ------------------------------------------------------------------------------------------------
  import layout files
--------------------------------------------------------------------------------------------------- */
@import 'layout/backgrounds';
@import 'layout/page';
@import 'layout/footer';
@import 'layout/typography';
@import 'layout/darkmode';

/* ------------------------------------------------------------------------------------------------
  debug stuff
--------------------------------------------------------------------------------------------------- */
.wip {
  border: 1px dashed rgba(orange, .80);
}

.custom-breadcrumb {
  a {
    text-decoration: none;
    color: $primary;
  }
}

.animate-glow {
  animation: glow 1s infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 0 5px -5px $secondary;
  }
  to {
    box-shadow: 0 0 5px 5px $secondary;
  }
}
