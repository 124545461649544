.q-table__container {
  .q-table, .q-field.q-select--multiple {
    background: $background2;
  }
}

.q-table thead {
  background: $background;
  th {
    border: unset;
  }
}

.q-table tbody tr:hover {
  background-color: $background2;
}

body.body--dark {
  .q-table__container {
    .q-table, .q-table thead, .q-field.q-select--multiple {
      background: $dark-page;
    }
  }
  .q-table tbody tr:hover {
    background-color: $dark-page;
  }
}

// FLOW EXECUTIONS
// tbd: move page specific components
.app-flow-execution-table {
  td:first-child {
    max-width: 1rem;
    background-color: $background2;
  }
  th:first-child {
    background-color: $background;
  }
  th:first-child, td:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
  }
  .app-to-detail-btn {
    img {
      font-size: 13px;
      margin-left: .5rem;
    }
    span {
      font-size: 13px;
    }
  }
  th, td {
    padding: 0 .3rem;
  }
  @media only screen and (min-width: 600px) { /* TODO cs: Opt: get quasar breakpoint variables to work here */
    th, td {
      padding: 7px 16px;
    }
  }
}

body.body--dark {
  .app-flow-execution-table {
    td:first-child {
      max-width: 1rem;
      background-color: $dark-page;
    }
    th:first-child, td:first-child {
      position: sticky;
      left: 0;
      z-index: 1;
    }
    th:first-child {
      background-color: $dark-page;
    }
    .app-to-detail-btn {
      .q-icon {
        -webkit-filter: invert(1);
                filter: invert(1);
      }
      .span {
        color: white
      }
    }
  }
}