@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';


  .custom-chart-legend {
    box-shadow: none !important;

    .q-table__card {
      box-shadow: none !important;
    }

    .q-table {
      background: none !important;
      border: none !important;
    }

    .q-table thead, .q-table tr, .q-table th, .q-table, td {
      border: none !important;
    }

    // working solution for the charts without touching tbody / chart template
    @media (max-width: $breakpoint-lg) {
      tbody {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }

  }

  body.body--dark {
    .custom-chart-legend {
      .q-badge--outline {
        background: $background2;
      }
    }
  }

