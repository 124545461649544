@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

  body.body--dark {
    .app-entity-query-editor-wrapper {
      background-color: $dark;
      &:hover {
        background-color: mix($dark,$secondary,95%);
      }
      .q-btn.text-negative {
        background-color: $negative;
        color: white !important;

        &:hover {
          background-color: mix($negative,$secondary,80%);
        }
      }
    }
  }
