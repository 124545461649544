.lds-ring {
  z-index: 2;
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  top: 50%;
  left: 50%;
  &-wrapper {
    min-height: 4rem;
  }
  &.centered {
    top: 40vh; // Optical illusion - 50vh looks "too far down". Place a bit higher
  }
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 2rem;
  height: 2rem;
  margin: -1rem;
  border: 2px solid $primary;
  border-radius: 50%;
  animation: lds-ring 1.1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $primary transparent transparent transparent;
}

.lds-ring div:nth-child(1) {animation-delay: -0.45s}
.lds-ring div:nth-child(2) {animation-delay: -0.3s;}
.lds-ring div:nth-child(3) {animation-delay: -0.15s;}

@keyframes lds-ring {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}
