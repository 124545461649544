.app-bg {
  background-color: $background;
}

.app-bg2 {
  background-color: $background2;
}

.bg-gray, .bg-grey {
  background-color: lighten($gray, 25%);
}
