.q-page-container {
  background-color: $background;
  .q-page {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 1440px;
    .q-tab-panels {
      background-color: unset;
    }
    .app-dashcard-container, .app-heartbeat-container, .app-message-queue-container, .app-supervisor-container, .app-disk-space-container, .app-redis-memory-usage-container {
      padding: 0;
      .app-dashcard, .app-heartbeat, .app-message-queue, .app-supervisor, .app-disk-space, .app-redis-memory-usage {
        @include user-select(none);
        .app-dashcard-label {
          font-size: 1rem;
        }
        .q-btn.q-btn--round {
          cursor: default;
        }
        &:hover {
          background-color: mix($background, $secondary, $mixVal);
          z-index: 1;
        }
      }
    }
    .app-flowcard-container {
      flex-direction: column;
      .app-flowcard {
        .app-flowcard-label {
          font-size: 1.5rem;
          margin-bottom: .5rem;
        }
        &:hover {
          background-color: mix($background, $secondary, $mixVal);
          z-index: 1;
          cursor: pointer;
        }
      }
    }
  }
}
// changed z-index because of issues with our menu, see
// https://synqupio.atlassian.net/browse/UI-350
.q-header, .q-footer {
  z-index: 2999;
}

body.body--dark{
  .q-page-container {
    background-color: $dark-page;
    .q-page {
      display: flex;
      > div > div {
        > h1, > h1 + p {
          color: $light;
        }
      }
      .app-dashcard-container, .app-heartbeat-container, .app-message-queue-container, .app-supervisor-container, .app-disk-space-container, .app-redis-memory-usage-container {
        .app-dashcard, .app-heartbeat, .app-message-queue, .app-supervisor, .app-disk-space, .app-redis-memory-usage {
          &:hover {
            background-color: mix($dark-page, $secondary, $mixVal);
          }
        }
      }
      .app-flowcard-container {
        .app-flowcard {
          &:hover {
            background-color: mix($dark-page, $secondary, $mixVal);
          }
        }
      }
    }
  }
}

.app-headline-container {
  min-height: 7rem;
}
