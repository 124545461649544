@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

  .app-confirm-delete-field {
    input {
      border-bottom: 2px solid $negative;
      transition: .5s;
    }
    &.match input {
      border-bottom: 2px solid $positive;
    }
  }
