@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.app-browser-path .q-field__inner {
  background-color: $background2;
}
body.body--dark {
  .app-browser-path .q-field__inner {
    background-color: $dark-page;
  }
}
