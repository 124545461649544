
  .app-user-name-input, .app-user-mail-input {
    .q-field__native.q-placeholder {
      padding-left: 1rem;
    }
  }
  .q-btn.disabled {
    opacity: .3 !important;
    &::before {
      box-shadow: none;
    }
  }
