
  .sq-dialog .inner-container {
    resize: both;
    max-width: 95vw;
    min-height: 20vh;
    &.size-small {
      min-width: 40%;
    }
    &.size-medium {
      min-width: 60%;
    }
    &.size-large {
      min-width: 80%;
    }
  }

  .sq-dialog-full-height {
    min-height: 90vh;
  }
