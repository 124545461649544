
  .config-tab-list {
    width: 200px;
    position: absolute;

    .q-tabs {
      max-height: 50vh;
      overflow-y: auto;
      overflow-x: hidden;

      .q-tab {
        justify-content: flex-start;
      }

      .q-tab__indicator {
        display: none;
      }
    }
  }

  .config-content {
    transition: all 0.8s ease;
  }

  .expert-mode {
    margin-left: 220px;
    transition: all 0.8s ease;
  }

  .q-panel-parent.overflow-visible {
    .q-panel.scroll {
      overflow: visible;
    }
  }
