
  .flow-severities-card  {
    body.screen--xs, body.screen--sm &, body.screen--md {
      margin-top: 16px;
    }
  }
  .severity-levels-card {
    body.screen--lg, body.screen--xl {
      margin-right: 16px;
    }
  }
  .chart-header {
    font-size: 16px;
    font-weight: 900;
    opacity: 1;
  }
  .validation-graph {
    body.screen--xs, body.screen--sm {
      width: 350px;
    }
    body.screen--md, body.screen--lg {
      width: 500px;
    }
  }
  .chart-filter {
    body.screen--xs, body.screen--sm {
      position: relative;
    }
    body.screen--md {
      margin-top: 15px;
      margin-right: 15px;
    }
    body.screen--lg, body.screen--xl {
      margin-top: 20px;
      margin-right: 20px;
    }
  }
