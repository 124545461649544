
  .q-field--dense .q-field__bottom {
    margin-bottom: 5px !important;
  }
  .expansion-header {
    padding: 0;
    .q-item__section--avatar {
      min-width: 1.5rem;
    }
  }
